export default class Animations {
  static activeSection = 0;

  constructor() {
    this.deviceReady = window.matchMedia(
      '(min-width: 1000px) and (min-height: 900px)'
    ).matches;

    if (this.deviceReady && $('.page-templates-tundra').length > 0) {
      Animations.init_velocity_effects();
      Animations.init_fullscreen_animation();
    }
  }

  static init_velocity_effects() {
    /* Custom effects registration - feature available in the Velocity UI pack */
    //none
    $.Velocity.RegisterEffect('translateUp', {
      defaultDuration: 1,
      calls: [[{ translateY: '-100%' }, 1]],
    });
    $.Velocity.RegisterEffect('translateDown', {
      defaultDuration: 1,
      calls: [[{ translateY: '100%' }, 1]],
    });
    $.Velocity.RegisterEffect('translateNone', {
      defaultDuration: 1,
      calls: [
        [
          {
            translateY: '0',
            opacity: '1',
            scale: '1',
            rotateX: '0',
            boxShadowBlur: '0',
          },
          1,
        ],
      ],
    });

    //scale down
    $.Velocity.RegisterEffect('scaleDown', {
      defaultDuration: 1,
      calls: [[{ opacity: '0', scale: '0.7' }, 1]],
    });
    //rotation
    $.Velocity.RegisterEffect('rotation', {
      defaultDuration: 1,
      calls: [[{ opacity: '0', rotateX: '90', translateY: '-100%' }, 1]],
    });
    $.Velocity.RegisterEffect('rotation.scroll', {
      defaultDuration: 1,
      calls: [[{ opacity: '0', rotateX: '90', translateY: '0' }, 1]],
    });
    //gallery
    $.Velocity.RegisterEffect('scaleDown.moveUp', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '-10%', scale: '0.9', boxShadowBlur: '40px' }, 0.2],
        [{ translateY: '-100%' }, 0.6],
        [{ translateY: '-100%', scale: '1', boxShadowBlur: '0' }, 0.2],
      ],
    });
    $.Velocity.RegisterEffect('scaleDown.moveUp.scroll', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '-100%', scale: '0.9', boxShadowBlur: '40px' }, 0.6],
        [{ translateY: '-100%', scale: '1', boxShadowBlur: '0' }, 0.4],
      ],
    });
    $.Velocity.RegisterEffect('scaleUp.moveUp', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '90%', scale: '0.9', boxShadowBlur: '40px' }, 0.2],
        [{ translateY: '0%' }, 0.6],
        [{ translateY: '0%', scale: '1', boxShadowBlur: '0' }, 0.2],
      ],
    });
    $.Velocity.RegisterEffect('scaleUp.moveUp.scroll', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '0%', scale: '0.9', boxShadowBlur: '40px' }, 0.6],
        [{ translateY: '0%', scale: '1', boxShadowBlur: '0' }, 0.4],
      ],
    });
    $.Velocity.RegisterEffect('scaleDown.moveDown', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '10%', scale: '0.9', boxShadowBlur: '40px' }, 0.2],
        [{ translateY: '100%' }, 0.6],
        [{ translateY: '100%', scale: '1', boxShadowBlur: '0' }, 0.2],
      ],
    });
    $.Velocity.RegisterEffect('scaleDown.moveDown.scroll', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '100%', scale: '0.9', boxShadowBlur: '40px' }, 0.6],
        [{ translateY: '100%', scale: '1', boxShadowBlur: '0' }, 0.4],
      ],
    });
    $.Velocity.RegisterEffect('scaleUp.moveDown', {
      defaultDuration: 1,
      calls: [
        [{ translateY: '-90%', scale: '0.9', boxShadowBlur: '40px' }, 0.2],
        [{ translateY: '0%' }, 0.6],
        [{ translateY: '0%', scale: '1', boxShadowBlur: '0' }, 0.2],
      ],
    });
    //catch up
    $.Velocity.RegisterEffect('translateUp.delay', {
      defaultDuration: 1,
      calls: [[{ translateY: '0%' }, 0.8, { delay: 100 }]],
    });
    //opacity
    $.Velocity.RegisterEffect('hide.scaleUp', {
      defaultDuration: 1,
      calls: [[{ opacity: '0', scale: '1.2' }, 1]],
    });
    $.Velocity.RegisterEffect('hide.scaleDown', {
      defaultDuration: 1,
      calls: [[{ opacity: '0', scale: '0.8' }, 1]],
    });
    //parallax
    $.Velocity.RegisterEffect('translateUp.half', {
      defaultDuration: 1,
      calls: [[{ translateY: '-50%' }, 1]],
    });
  }

  static init_fullscreen_animation() {
    // Set animation params
    $('body').attr('data-hijacking', 'on');
    $('body').attr('data-animation', 'scaleDown');

    // Set variables
    const hijacking = $('body').data('hijacking');
    const animationType = $('body').data('animation');
    const section = '[data-animation-section]';
    const container = '[data-animation-container]';
    let delta = 0;
    const scrollThreshold = 5;
    let actual = 0;
    let animating = false;
    const sectionsAvailable = $(section);

    bindEvents();

    $(window).on('resize', function () {
      this.deviceReady = window.matchMedia(
        '(max-width: 1000px) and (max-height: 900px)'
      ).matches;

      if (!this.deviceReady) {
        resetSectionStyle();
        $(window).off('DOMMouseScroll mousewheel', scrollHijacking);
        $(window).off('scroll', scrollAnimation);
        $(document).off('keydown');
      } else {
        bindEvents();
      }
    });

    function bindEvents() {
      // scroll navigation
      if (hijacking === 'on') {
        initHijacking();
        document.body.addEventListener('wheel', scrollHijacking, {
          passive: false,
        });
      } else {
        scrollAnimation();
        window.addEventListener('scroll', scrollAnimation, { passive: false });
      }

      // Keyboard navigation
      $(document).on('keydown', (event) => {
        if (event.which === '40') {
          event.preventDefault();
          nextSection();
        } else if (event.which === '38') {
          event.preventDefault();
          prevSection();
        }
      });

      // Accelerator navigation
      $(document).on('click', '[data-trigger="scrolldown"]', (event) => {
        event.preventDefault();
        nextSection();
      });

      // Anchors navigation
      $(document).on('click', '[data-anchor]', function (e) {
        e.preventDefault();

        const target = $(this).attr('href');
        const index = $('[data-animation-section]').index($(target));

        if (index >= 0 && index !== actual) {
          setSection(index);
        }
      });
    }

    function scrollAnimation() {
      //normal scroll - use requestAnimationFrame (if defined) to optimize performance
      if (!window.requestAnimationFrame) {
        animateSection();
      } else {
        window.requestAnimationFrame(animateSection);
      }
    }

    function animateSection() {
      const scrollTop = $(window).scrollTop();
      const windowHeight = $(window).height();

      sectionsAvailable.each(function () {
        const actualBlock = $(this);
        const offset = scrollTop - actualBlock.offset().top;

        //according to animation type and window scroll, define animation parameters
        const animationValues = setSectionAnimation(
          offset,
          windowHeight,
          animationType
        );

        transformSection(
          actualBlock.children('[data-animation-container]'),
          animationValues[0],
          animationValues[1],
          animationValues[2],
          animationValues[3],
          animationValues[4]
        );

        if (offset >= 0 && offset < windowHeight) {
          actualBlock.addClass('visible');
        } else {
          actualBlock.removeClass('visible');
        }
      });
    }

    function transformSection(
      element,
      translateY,
      scaleValue,
      rotateXValue,
      opacityValue,
      boxShadow
    ) {
      //transform sections - normal scroll
      element.velocity(
        {
          translateY: `${translateY}vh`,
          scale: scaleValue,
          rotateX: rotateXValue,
          opacity: opacityValue,
          boxShadowBlur: `${boxShadow}px`,
          translateZ: 0,
        },
        0
      );
    }

    function initHijacking() {
      // initialize section style - scrollhijacking
      const visibleSection = sectionsAvailable.filter('.visible');
      const topSection = visibleSection.prevAll(section);
      const bottomSection = visibleSection.nextAll(section);
      const animationParams = selectAnimation(animationType, false);
      const animationVisible = animationParams[0];
      const animationTop = animationParams[1];
      const animationBottom = animationParams[2];

      visibleSection
        .children('[data-animation-container]')
        .velocity(animationVisible, 1, () => {
          visibleSection.css('opacity', 1);
          topSection.css('opacity', 1);
          bottomSection.css('opacity', 1);
        });
      topSection
        .children('[data-animation-container]')
        .velocity(animationTop, 0);
      bottomSection
        .children('[data-animation-container]')
        .velocity(animationBottom, 0);
    }

    function scrollHijacking(event) {
      // on mouse scroll - check if animate section
      // condition to keep scroll inside section if diff exist

      const currentSection = $(section).eq(actual);
      const currentContainer = currentSection.find(container);
      const currentContent = currentSection.find(container).children();
      const diff = Math.floor(
        currentContent.outerHeight() - currentContainer.outerHeight()
      );

      if (diff <= 0) {
        if (event.wheelDelta > 0) {
          delta--;

          if (Math.abs(delta) >= scrollThreshold) {
            prevSection();
          }
        } else {
          delta++;

          if (delta >= scrollThreshold) {
            nextSection();
          }
        }
      } else if (event.wheelDelta > 0) {
        delta--;

        if (
          Math.abs(delta) >= scrollThreshold &&
          currentContainer.scrollTop() === 0
        ) {
          prevSection();
        }
      } else {
        delta++;

        if (delta >= scrollThreshold && currentContainer.scrollTop() >= diff) {
          nextSection();
        }
      }

      return false;
    }

    function prevSection(event) {
      //go to previous section
      if (typeof event !== 'undefined') {
        event.preventDefault();
      }

      let visibleSection = sectionsAvailable.filter('.visible');
      const middleScroll = Boolean(
        hijacking === 'off' &&
          $(window).scrollTop() !== visibleSection.offset().top
      );

      visibleSection = middleScroll
        ? visibleSection.next(section)
        : visibleSection;

      const animationParams = selectAnimation(
        animationType,
        middleScroll,
        'prev'
      );

      unbindScroll(visibleSection.prev(section), animationParams[3]);

      if (!animating && !visibleSection.is(':first-child')) {
        $('.navbar').addClass('is-sticky').removeClass('is-hidden');
        $('body').removeClass('header-is-hidden').addClass('header-is-sticky');
        animating = true;
        visibleSection
          .removeClass('visible')
          .children('[data-animation-container]')
          .velocity(animationParams[2], animationParams[3], animationParams[4])
          .end()
          .prev(section)
          .addClass('visible')
          .children('[data-animation-container]')
          .velocity(
            animationParams[0],
            animationParams[3],
            animationParams[4],
            () => {
              animating = false;

              if (hijacking === 'off') $(window).on('scroll', scrollAnimation);
            }
          );

        actual -= 1;
      }

      resetScroll();
    }

    function nextSection(event) {
      //go to next section
      if (typeof event !== 'undefined') {
        event.preventDefault();
      }

      const visibleSection = sectionsAvailable.filter('.visible');
      const middleScroll = Boolean(
        hijacking === 'off' &&
          $(window).scrollTop() !== visibleSection.offset().top
      );

      const animationParams = selectAnimation(
        animationType,
        middleScroll,
        'next'
      );

      unbindScroll(visibleSection.next(section), animationParams[3]);

      if (!animating && !visibleSection.is(':last-of-type')) {
        $('.navbar').addClass('is-hidden').removeClass('is-sticky');
        $('body').addClass('header-is-hidden').removeClass('header-is-sticky');
        animating = true;
        visibleSection
          .removeClass('visible')
          .children('[data-animation-container]')
          .velocity(animationParams[1], animationParams[3], animationParams[4])
          .end()
          .next(section)
          .addClass('visible')
          .children('[data-animation-container]')
          .velocity(
            animationParams[0],
            animationParams[3],
            animationParams[4],
            () => {
              animating = false;

              if (hijacking === 'off') $(window).on('scroll', scrollAnimation);
            }
          );

        actual += 1;
      }

      resetScroll();
    }

    function setSection(index, event) {
      //go to next section
      if (typeof event !== 'undefined') {
        event.preventDefault();
      }

      const visibleSection = sectionsAvailable.filter('.visible');
      const middleScroll = Boolean(
        hijacking === 'off' &&
          $(window).scrollTop() !== visibleSection.offset().top
      );

      const animationParams = selectAnimation(
        animationType,
        middleScroll,
        index > actual ? 'next' : 'prev'
      );

      unbindScroll($(sectionsAvailable[index]), animationParams[3]);

      if (!animating) {
        animating = true;
        visibleSection
          .removeClass('visible')
          .children('[data-animation-container]')
          .velocity(
            animationParams[index > actual ? 1 : 2],
            animationParams[3],
            animationParams[4]
          );
        $(sectionsAvailable[index])
          .addClass('visible')
          .children('[data-animation-container]')
          .velocity(
            animationParams[0],
            animationParams[3],
            animationParams[4],
            () => {
              animating = false;

              if (hijacking === 'off') $(window).on('scroll', scrollAnimation);
            }
          );
        actual = index;
      }

      resetScroll();
    }

    function unbindScroll(scrollSection, time) {
      //if clicking on navigation - unbind scroll and animate using custom velocity animation
      if (hijacking === 'off') {
        $(window).off('scroll', scrollAnimation);

        if (animationType === 'catch') {
          $('body, html').scrollTop(scrollSection.offset().top);
        } else {
          scrollSection.velocity('scroll', { duration: time });
        }
      }
    }

    function resetScroll() {
      delta = 0;
    }

    function resetSectionStyle() {
      //on mobile - remove style applied with jQuery
      sectionsAvailable
        .children('[data-animation-container]')
        .each(function () {
          $(this).attr('style', '');
        });
    }

    function selectAnimation(animationName, middleScroll, direction) {
      // select section animation - scrollhijacking
      let animationVisible = 'translateNone';
      let animationTop = 'translateUp';
      let animationBottom = 'translateDown';
      let easing = 'ease';
      let animDuration = 800;

      switch (animationName) {
        case 'scaleDown':
          animationTop = 'scaleDown';
          easing = 'easeInCubic';
          break;
        case 'rotate':
          if (hijacking === 'off') {
            animationTop = 'rotation.scroll';
            animationBottom = 'translateNone';
          } else {
            animationTop = 'rotation';
            easing = 'easeInCubic';
          }

          break;
        case 'gallery':
          animDuration = 1500;

          if (middleScroll) {
            animationTop = 'scaleDown.moveUp.scroll';
            animationVisible = 'scaleUp.moveUp.scroll';
            animationBottom = 'scaleDown.moveDown.scroll';
          } else {
            animationVisible =
              direction === 'next' ? 'scaleUp.moveUp' : 'scaleUp.moveDown';
            animationTop = 'scaleDown.moveUp';
            animationBottom = 'scaleDown.moveDown';
          }

          break;
        case 'catch':
          animationVisible = 'translateUp.delay';
          break;
        case 'opacity':
          animDuration = 700;
          animationTop = 'hide.scaleUp';
          animationBottom = 'hide.scaleDown';
          break;
        case 'fixed':
          animationTop = 'translateNone';
          easing = 'easeInCubic';
          break;
        case 'parallax':
          animationTop = 'translateUp.half';
          easing = 'easeInCubic';
          break;
      }

      return [
        animationVisible,
        animationTop,
        animationBottom,
        animDuration,
        easing,
      ];
    }

    function setSectionAnimation(sectionOffset, windowHeight, animationName) {
      // select section animation - normal scroll
      let scale = 1;
      let translateY = 100;
      let rotateX = '0deg';
      let opacity = 1;
      let boxShadowBlur = 0;

      if (sectionOffset >= -windowHeight && sectionOffset <= 0) {
        // section entering the viewport
        translateY = (-sectionOffset * 100) / windowHeight;

        switch (animationName) {
          case 'scaleDown':
            scale = 1;
            opacity = 1;
            break;
          case 'rotate':
            translateY = 0;
            break;
          case 'gallery':
            if (
              sectionOffset >= -windowHeight &&
              sectionOffset < -0.9 * windowHeight
            ) {
              scale = -sectionOffset / windowHeight;
              translateY = (-sectionOffset * 100) / windowHeight;
              boxShadowBlur = 400 * (1 + sectionOffset / windowHeight);
            } else if (
              sectionOffset >= -0.9 * windowHeight &&
              sectionOffset < -0.1 * windowHeight
            ) {
              scale = 0.9;
              translateY =
                (-(9 / 8) * (sectionOffset + 0.1 * windowHeight) * 100) /
                windowHeight;
              boxShadowBlur = 40;
            } else {
              scale = 1 + sectionOffset / windowHeight;
              translateY = 0;
              boxShadowBlur = (-400 * sectionOffset) / windowHeight;
            }

            break;
          case 'catch':
            if (
              sectionOffset >= -windowHeight &&
              sectionOffset < -0.75 * windowHeight
            ) {
              translateY = 100;
              boxShadowBlur = (1 + sectionOffset / windowHeight) * 160;
            } else {
              translateY = (-(10 / 7.5) * sectionOffset * 100) / windowHeight;
              boxShadowBlur = (-160 * sectionOffset) / (3 * windowHeight);
            }

            break;
          case 'opacity':
            translateY = 0;
            scale = ((sectionOffset + 5 * windowHeight) * 0.2) / windowHeight;
            opacity = (sectionOffset + windowHeight) / windowHeight;
            break;
        }
      } else if (sectionOffset > 0 && sectionOffset <= windowHeight) {
        //section leaving the viewport - still has the '.visible' class
        translateY = (-sectionOffset * 100) / windowHeight;

        switch (animationName) {
          case 'scaleDown':
            scale = (1 - (sectionOffset * 0.3) / windowHeight).toFixed(5);
            opacity = (1 - sectionOffset / windowHeight).toFixed(5);
            translateY = 0;
            // boxShadowBlur = 40*(sectionOffset/windowHeight);

            break;
          case 'rotate':
            opacity = (1 - sectionOffset / windowHeight).toFixed(5);
            rotateX = `${(sectionOffset * 90) / windowHeight}deg`;
            translateY = 0;
            break;
          case 'gallery':
            if (sectionOffset >= 0 && sectionOffset < 0.1 * windowHeight) {
              scale = (windowHeight - sectionOffset) / windowHeight;
              translateY = -(sectionOffset / windowHeight) * 100;
              boxShadowBlur = (400 * sectionOffset) / windowHeight;
            } else if (
              sectionOffset >= 0.1 * windowHeight &&
              sectionOffset < 0.9 * windowHeight
            ) {
              scale = 0.9;
              translateY =
                (-(9 / 8) * (sectionOffset - (0.1 * windowHeight) / 9) * 100) /
                windowHeight;
              boxShadowBlur = 40;
            } else {
              scale = sectionOffset / windowHeight;
              translateY = -100;
              boxShadowBlur = 400 * (1 - sectionOffset / windowHeight);
            }

            break;
          case 'catch':
            if (sectionOffset >= 0 && sectionOffset < windowHeight / 2) {
              boxShadowBlur = (sectionOffset * 80) / windowHeight;
            } else {
              boxShadowBlur = 80 * (1 - sectionOffset / windowHeight);
            }

            break;
          case 'opacity':
            translateY = 0;
            scale = ((sectionOffset + 5 * windowHeight) * 0.2) / windowHeight;
            opacity = (windowHeight - sectionOffset) / windowHeight;
            break;
          case 'fixed':
            translateY = 0;
            break;
          case 'parallax':
            translateY = (-sectionOffset * 50) / windowHeight;
            break;
        }
      } else if (sectionOffset < -windowHeight) {
        //section not yet visible
        translateY = 100;

        switch (animationName) {
          case 'scaleDown':
            scale = 1;
            opacity = 1;
            break;
          case 'gallery':
            scale = 1;
            break;
          case 'opacity':
            translateY = 0;
            scale = 0.8;
            opacity = 0;
            break;
        }
      } else {
        //section not visible anymore
        translateY = -100;

        switch (animationName) {
          case 'scaleDown':
            scale = 0;
            opacity = 0.7;
            translateY = 0;
            break;
          case 'rotate':
            translateY = 0;
            rotateX = '90deg';
            break;
          case 'gallery':
            scale = 1;
            break;
          case 'opacity':
            translateY = 0;
            scale = 1.2;
            opacity = 0;
            break;
          case 'fixed':
            translateY = 0;
            break;
          case 'parallax':
            translateY = -50;
            break;
        }
      }

      return [translateY, scale, rotateX, opacity, boxShadowBlur];
    }
  }
}
