import '@hexadrone/scss/app.scss';
import Header from '@hexadrone/js/services/header';
import Utils from '@hexadrone/js/services/utils';
import Form from '@hexadrone/js/services/form';
import Map from '@hexadrone/js/services/map';
import Slider from '@hexadrone/js/services/slider';
import Animations from './services/animations';

const initApp = () => {
  new Header();
  new Utils();
  new Form();
  new Map();
  new Slider();
  new Animations();
};

if (document.readyState === 'loading') {
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', initApp);
} else {
  // `DOMContentLoaded` has already fired
  initApp();
}
