import L from 'leaflet';
import omnivore from '@mapbox/leaflet-omnivore';

export default class Map {
  static #map;

  constructor() {
    Map.map();
    Map.add_kml();
  }

  static map() {
    Map.map_api('[data-id="wpiris-map"]');
  }

  static map_api($element) {
    if ($($element).length > 0) {
      const markerBounds = [];
      const markers = [];
      const $markers = $($element).find('[data-id="wpiris-map-marker"]');

      Map.#map = L.map('map', {
        center: [
          parseFloat(window.Theme.latitude),
          parseFloat(window.Theme.longitude),
        ],
        zoom: 11,
        scrollWheelZoom: false,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(Map.#map);

      $.each($markers, function () {
        const markerIcon = L.icon({
          iconUrl: `${window.Theme.theme_url}/assets/images/markers/marker.svg`,
          iconSize: [30, 30],
        });

        const marker = L.featureGroup()
          .addLayer(
            L.marker(
              [
                parseFloat($(this).data('lat')),
                parseFloat($(this).data('lng')),
              ],
              { icon: markerIcon }
            )
          )
          .bindPopup($(this).html(), {
            className: 'light',
            autoPanPadding: L.point(30, 30),
          })
          .addTo(Map.#map);

        markers.push(marker);

        markerBounds.push([
          parseFloat($(this).data('lat')),
          parseFloat($(this).data('lng')),
        ]);
      });

      const group = L.featureGroup(markers);

      Map.#map.fitBounds(group.getBounds(), { padding: [50, 100] });
    }
  }

  static add_kml() {
    const element = '[data-id="wpiris-kml-file"]';

    if ($(element).length > 0) {
      const kml = $(element).attr('data-kml');

      const customLayer = L.geoJson(null, {
        style() {
          return { color: '#000' };
        },
      });

      const runLayer = omnivore.kml(kml, null, customLayer).addTo(Map.#map);

      runLayer.on('ready', () => {
        Map.#map.fitBounds(runLayer.getBounds());
      });
    }
  }
}
