export default class Form {
  constructor() {
    Form.select();
  }

  static select() {
    $('select')
      .parent()
      .not('.formSelect')
      .children('select')
      .wrap('<div class="formSelect"></div>');
  }
}
